<template>
  <router-link
    :to="{
      path: this.$route.matched[0].path + '/' + data.slug,
      params: { children: data.slug },
    }"
    tag="a"
    class="cart"
  >
    <div class="cart__img">
      <div class="cart__img-wrap">
        <v-lazy-image
          :src="getUrlIcon(data.attachment)"
          rel="preload"
          width="361"
          height="309"
          alt="solutions"
        />
      </div>
    </div>
    <div class="cart__text">
      <h2 class="cart__title">
        <pre>{{ getTranslatedName(data.translations, $i18n.locale) }}</pre>
      </h2>
    </div>
    <div class="cart__links" v-if="links">
      <object v-for="link in links" :key="link">
        <router-link :to="link.slug" tag="a">{{ link.title }}</router-link>
      </object>
    </div>
  </router-link>
</template>

<script>
import VLazyImage from "v-lazy-image";

export default {
  components: {
    VLazyImage,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    links: {
      type: Array,
    },
  },
  methods: {
    getTranslatedName(transArray, locale) {
      let name = "";
      transArray.forEach((element) => {
        if (element.locale === locale) {
          name = element.name;
        }
      });
      return name;
    },
    getUrlIcon(transArray) {
      let iconUrl = "";
      transArray.forEach((element) => {
        if (element.group === "icon") {
          iconUrl = element.url;
        }
      });
      return iconUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.cart {
  position: relative;
  width: calc(33.333% - 188px / 2);
  max-width: 361px;
  min-width: 270px;

  &:nth-of-type(n + 1) {
    @include property("margin-right", 94, 60, true, 1670, 1024);
  }

  &:nth-of-type(3n + 3) {
    @media (min-width: 1024px) {
      margin-right: 0;
    }
  }

  &:nth-of-type(2n + 2) {
    @media (max-width: 1023.98px) {
      margin-right: 0;
    }
  }

  &:nth-of-type(n + 4) {
    @media (min-width: 1024px) {
      margin-top: calc(60px + (120 - 60) * ((100vw - 1024px) / (1670 - 1024)));
    }
    @media (min-width: 1670px) {
      margin-top: 120px;
    }
  }

  &:nth-of-type(n + 3) {
    @media (max-width: 1023.98px) {
      margin-top: 60px;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 1023.98px) {
    width: calc(50% - 188px / 3);
  }

  @media (max-width: 767.98px) {
    width: 100%;
    margin-right: 0 !important;

    &:not(:first-child) {
      margin-top: calc(
        40px + (60 - 40) * ((100vw - 320px) / (768 - 320))
      ) !important;
    }
  }

  @media (hover: hover) {
    &::after {
      opacity: 0;
      transition: all 0.3s linear;
    }

    &:hover {
      text-decoration: none;
      &::after {
        opacity: 1;
      }

      .cart__img {
        &::before {
          opacity: 0 !important;
        }
      }

      .cart__img::after {
        opacity: 1 !important;
      }
    }
  }

  &__img {
    position: relative;
    z-index: 1;
    padding-bottom: 85.6%;

    .network__wrap &,
    .ip-telephony &,
    .ip-telephony-security & {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent
          linear-gradient(230deg, #f9b80e00 0%, #f9b80e4d 100%, #7d5c07 100%);
        z-index: 5;
        opacity: 0;
        transition: all 0.3s linear;
      }
    }

    .ip-telephony__wrap & {
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(
          230deg,
          #f9b80e26 0%,
          #f9b80e4d 100%,
          #7d5c07 100%
        );
        opacity: 0.7;
      }
    }

    &-wrap {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      &::before {
        content: "";
        position: absolute;
        top: -20px;
        left: 20px;
        width: 100%;
        height: 100%;
        z-index: 2;
        border: 1px solid #f9b80e;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;

      .ip-telephony-security & {
        object-fit: contain;
      }
    }
  }

  &__text {
    position: absolute;
    z-index: 9;
    left: 0;
    top: 0;
  }

  &__title {
    position: relative;
    top: -20px;
    @include property("left", -40, 0, true, 1270, 768);
    display: inline-block;
    padding: 5px 30px 5px 15px;
    font-weight: 700;
    max-width: 340px;
    background: #f9b80e;
    text-transform: uppercase;

    .fs32 & {
      @include property("font-size", 32, 21, true, 1400, 1024);
    }

    .fs28 & {
      @include property("font-size", 28, 21, true, 1400, 1024);
    }

    @media (max-width: 1023.98px) {
      font-size: 32px;
      padding: 5px 15px;
    }

    @media (min-width: 576px) and (max-width: 768px) {
      font-size: calc(21px + (32 - 21) * ((100vw - 576px) / (768 - 576)));
    }

    @media (max-width: 576px) {
      font-size: 21px;
    }
  }

  &__links {
    @include property('margin-top', 28, 15);
    margin-left: 25px;
    object {
      display: grid;
      gap: 15px;

      &:nth-child(1) {
        a {
          &::before {
            background: url("~@/assets/images/display.svg") no-repeat;
            background-size: 100% 100%;
          }
        }
      }

      &:nth-child(2) {
        a {
          &::before {
            background: url("~@/assets/images/settings.svg") no-repeat;
            background-size: 100% 100%;
          }
        }
      }

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      a {
        position: relative;
        display: inline-flex;
        align-items: center;
        font-weight: 700;
        font-size: 18px;
        letter-spacing: 0.45px;
        color: #f9b80e;
        text-transform: lowercase;

        &::before {
          content: "";
          display: block;
          width: 21px;
          height: 21px;
          flex: 0 0 auto;
          margin-right: 11px;
        }
      }
    }
  }
}
</style>
